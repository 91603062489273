import { createModel } from '@rematch/core';
import _lodash from 'lodash';
import Router from 'next/router';
import {
  getBrandDetails,
  getIndustries,
  getMerchantPreferences,
  updateOrCreateMerchantPreferences,
} from 'src/lib/api/integrated-onboarding';
import { setCompanyLogoAPI } from 'src/lib/api/settings';
import { getStorage } from 'src/lib/storage';
import { OnboardingPreference } from 'src/modules/home/models/types';
import { RootModel } from 'src/store/models';

export const IntegratedOnboardingStep = {
  ABOUT: 'about',
  BRAND_SETUP: 'brand-setup',
  INDUSTRY_AND_LANGUAGE: 'industry-and-language',
  WEBPUSH_OR_EMAIL: 'webpush-or-email',
  ENABLE_THEME_APP_EXTENSION: 'enable-theme-app-extension',
  TRIAL: 'trial',
} as const;

export const stepOrder = {
  [IntegratedOnboardingStep.ABOUT]: 1,
  [IntegratedOnboardingStep.BRAND_SETUP]: 2,
  [IntegratedOnboardingStep.INDUSTRY_AND_LANGUAGE]: 3,
  [IntegratedOnboardingStep.WEBPUSH_OR_EMAIL]: 4,
  [IntegratedOnboardingStep.ENABLE_THEME_APP_EXTENSION]: 5,
};

type IntegratedOnboardingState = {
  onboarding: OnboardingState;
  isLoading: boolean;
  doPreferencesExist: boolean;
};

export type OnboardingState = {
  brand_url: string;
  logo_url: string;
  preferred_language: string;
  industry: string;
  product_sold: string;
  onboarding_setup_choice: OnboardingPreference;
};

const initialState: IntegratedOnboardingState = {
  onboarding: {
    brand_url: '',
    logo_url: '',
    preferred_language: '',
    industry: '',
    product_sold: '',
    onboarding_setup_choice: 'webpush',
  },
  isLoading: false,
  doPreferencesExist: false,
};

const integratedOnboarding = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    goToOnboardingStep(
      payload: {
        jumpTo?: (typeof IntegratedOnboardingStep)[keyof typeof IntegratedOnboardingStep];
      },
      rootState,
    ) {
      if (payload.jumpTo === IntegratedOnboardingStep.TRIAL) {
        const { orderCount } = rootState.integratedDashboard;

        if (orderCount < 2000) {
          Router.replace('/?trial=19');
        } else if (orderCount > 2000 && orderCount < 10_000) {
          Router.replace('/?trial=38');
        } else if (orderCount > 10_000) {
          Router.replace('/?trial=79');
        }
        return;
      }

      if (typeof payload.jumpTo !== 'undefined') {
        this.syncOnboardingState();
        Router.replace(`/onboarding/${payload.jumpTo}`);
      }
    },

    getPersistOnboardingStepKey(_, rootState) {
      const { user } = rootState.user;

      return `integrated-onboarding-step-${user.website.subdomain}`;
    },

    clearPersistedOnboardingStep() {
      const storage = getStorage();
      storage.clear(
        dispatch.integratedOnboarding.getPersistOnboardingStepKey(),
      );
    },

    async extractLogoFromSubAccount() {
      try {
        this.setIsLoading(true);
        const { error, data } = await getBrandDetails();
        if (error) {
          this.setOnboardingState({
            logo_url: '',
          });
          this.setIsLoading(false);
          return;
        }

        const logo = data?.logo_url ?? '';
        setCompanyLogoAPI(logo);
        dispatch.user.setWebsitePreference({
          key: 'company_logo',
          value: logo,
        });

        this.setOnboardingState({
          logo_url: logo,
        });
        this.setIsLoading(false);
        return;
      } catch {
        this.setIsLoading(false);
      }
    },

    async fetchIndustries() {
      try {
        const { data, error } = await getIndustries();
        if (error) {
          dispatch.saveToast.showError(
            'integrated_onboarding_industries_error',
          );
          return [];
        }

        return data.map(i => {
          return { value: i.value, label: i.display };
        });
      } catch {
        return [];
      }
    },

    /**
     * This only runs when a merchant presses the next button
     * if there is only one field in the state, it means the record doesn't exist in the backend yet
     * so we create a record in the backend with a POST req
     * so that we can update it later with the PUT req from the else block
     */
    async syncOnboardingState(_, rootState) {
      const state = { ...rootState.integratedOnboarding.onboarding };
      const stateWithNoEmptyFields = _lodash.omitBy(state, x => !x);

      if (Object.keys(stateWithNoEmptyFields).length) {
        if (!rootState.integratedOnboarding.doPreferencesExist) {
          const { error } = await updateOrCreateMerchantPreferences(
            stateWithNoEmptyFields as OnboardingState,
            true,
          );
          if (error) {
            dispatch.saveToast.showError(
              'integrated_onboarding_create_preference_error',
            );
          } else {
            dispatch.integratedOnboarding.setPreferenceError(true);
          }
        } else {
          const { error } = await updateOrCreateMerchantPreferences(
            stateWithNoEmptyFields as OnboardingState,
          );
          if (error) {
            dispatch.saveToast.showError(
              'integrated_onboarding_update_preference_error',
            );
          }
        }
      }
    },

    async fetchMerchantPreferences(_) {
      try {
        dispatch.integratedOnboarding.setIsLoading(true);
        const { data, error } = await getMerchantPreferences();

        if (error) {
          if ((error as any)?.errorType === 'PreferencesNotFound') {
            dispatch.integratedOnboarding.setPreferenceError(false);
          }
        } else {
          dispatch.integratedOnboarding.setPreferenceError(true);
        }
        this.setOnboardingState({
          brand_url: data?.brand_url ?? '',
          logo_url: data?.logo_url ?? '',
          industry: data?.industry ?? '',
          preferred_language: data?.preferred_language ?? '',
          product_sold: data?.product_sold ?? '',
          onboarding_setup_choice: data?.onboarding_setup_choice ?? 'webpush',
        });
        dispatch.integratedOnboarding.setIsLoading(false);
      } catch {
        dispatch.integratedOnboarding.setIsLoading(false);
      }
    },
  }),

  reducers: {
    setOnboardingState(
      state,
      payload: Partial<IntegratedOnboardingState['onboarding']>,
    ) {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
        },
      };
    },
    setIsLoading(state, payload: boolean) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    setPreferenceError(
      state,
      payload: IntegratedOnboardingState['doPreferencesExist'],
    ) {
      return {
        ...state,
        doPreferencesExist: payload,
      };
    },
  },
});

export default integratedOnboarding;
