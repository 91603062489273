import posthog from 'posthog-js';
import { v4 as uuidv4 } from 'uuid';
import { serverSideTracking } from '../api/server-side-tracking';
import { TRACKING_EVENTS } from '../constants';

export const identify = (identifier: string, traits: AnyObject) => {
  if (typeof window?.analytics?.identify === 'function')
    window.analytics.identify(identifier, traits);
};

export const trackPage = () => {
  if (typeof window?.analytics?.page === 'function') window.analytics.page();

  const { pathname } = window.location;
  if (pathname && posthog) {
    const url = window.origin + pathname;

    posthog.capture('$pageview', {
      $current_url: `${url}${window.location.search}`,
    });
  }
  serverSideTracking({
    event_name: TRACKING_EVENTS.PAGE_VIEW,
    event_uuid: uuidv4(),
    event_properties: {
      page_url: pathname,
    },
  });
};

export const simpleTrack = (identifier: string) => {
  if (typeof window?.analytics?.track === 'function')
    window.analytics.track(identifier);
};

export const trackFbq = (
  method: string,
  event: string,
  data?: AnyObject,
  eventId?: AnyObject,
) => {
  // Pixel
  if (typeof window.fbq === 'function') {
    window.fbq(method, event, data, eventId);
  }
};

export const trackGtag = (event: string, eventParams?: any) => {
  // Pixel
  if (typeof window.gtag === 'function') {
    window.gtag(event, eventParams);
  }
};
