import { AxiosError } from 'axios';
import { CampaignType, CampaignDispatchInfo } from 'src/modules/campaigns/models';
import { getAxios, getUser, AxiosResponse, APIResponseObject } from './axios';
import { logErrorToSentry } from '../debug-utils';

export interface CampaignParams {
  type?: CampaignType;
  limit?: number;
  offset?: number;
  start_time?: Date;
  end_time?: Date;
  dispatch_info?: CampaignDispatchInfo;
}

const genericErrorMessage =
  'An unexpected error has occurred; please try again. Contact support if the issue persists. Error: ';

export const fetchCampaignsAPI: (args: CampaignParams) => AxiosResponse = ({
  type = CampaignType.SENT,
  limit = 10,
  offset = 0,
  start_time = new Date(0),
  end_time = new Date(),
  dispatch_info,
}) => {
  const axios = getAxios();
  let url = `/dashboard/campaign/?campaign_type=${type}&limit=${limit}&offset=${offset}&start_time=${start_time.toISOString()}&end_time=${end_time.toISOString()}`;
  if (dispatch_info) {
    url += `&dispatch_info=${dispatch_info}`;
  }
  return axios
    .get(
      url,
    )
    .then(res => {
      return { data: res.data.result, error: null };
    })
    .catch(error => {
      logErrorToSentry({
        error: 'Failed to fetch campaign',
        extras: {
          error,
          method: 'GET',
          url: `/dashboard/campaign/?campaign_type=${type}&limit=${limit}&offset=${offset}&start_time=${start_time.toISOString()}&end_time=${end_time.toISOString()}`,
        },
      });

      return {
        error,
        data: null,
      };
    });
};

const postSentryHandler = (error: string | AxiosError, campaign): void => {
  logErrorToSentry({
    error: 'Failed to create campaign',
    extras: {
      error,
      campaign,
      method: 'POST',
    },
  });
};

export const postCampaignAPI = (campaign: any): AnyObject => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .post(`/accounts/${user.website.subdomain}/campaigns/`, campaign, {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    })
    .then(res => {
      const {
        data: { result, error },
      } = res;

      if (!error) return { data: result };

      postSentryHandler(error, campaign);

      return { error: `${genericErrorMessage}"${error}"` };
    })
    .catch((error: AxiosError) => {
      postSentryHandler(error, campaign);
      return { error: `${genericErrorMessage}"${error.message}"` };
    });
};

const putSentryHandler = (
  error: string | AxiosError,
  campaign,
  id: number,
  exceptionString: string,
): void => {
  const user = getUser();
  logErrorToSentry({
    error: exceptionString,
    extras: {
      campaign,
      method: 'PUT',
      url: `/accounts/${user?.website?.subdomain}/campaigns/${id}`,
    },
  });
};

export const putCampaignAPI = (id: number, campaign: any) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .put(`/accounts/${user.website.subdomain}/campaigns/${id}/`, campaign, {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    })
    .then(res => {
      if (!res) {
        putSentryHandler(
          'Response is undefined for campaign creator put request',
          campaign,
          id,
          'Response is undefined',
        );
        return { error: `${genericErrorMessage}"Response is undefined"` };
      }

      const {
        data: { result, error },
      } = res;

      if (!error) return { data: result };

      putSentryHandler(error, campaign, id, 'Failed to edit campaign');

      return { error: `${genericErrorMessage}"${error}"` };
    })
    .catch((error: AxiosError<APIResponseObject>) => {
      putSentryHandler(error, campaign, id, 'Failed to edit campaign');

      return {
        error:
          error.response?.data?.error === 'Can not update sent campaign'
            ? 'Your campaign has already been sent; please exit and/or create a new campaign.'
            : `${genericErrorMessage}"${error.message}"`,
      };
    });
};

export const deleteCampaignAPI = (campaignId: number) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .delete(
      `/api/v1/accounts/${user.website.subdomain}/campaigns/${campaignId}/`,
      {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
      },
    )
    .then(res => {
      if (res.data.status === 200) {
        return { data: res.data.result, error: null };
      }
      return {
        data: null,
        error: Error('Unsuccessful deletion'),
      };
    })
    .catch(error => ({
      error,
      data: null,
    }));
};

export const resendCampaignAPI = (campaignId: number) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .post(`/dashboard/campaign/${campaignId}/resend/`, {
      headers: {
        Authorization: `JWT ${user.token}`,
      },
    })
    .then(res => {
      if (res.data.status === 200) {
        return { data: res.data.result, error: null };
      }
      return {
        data: null,
        error: Error('Unsuccessful resend'),
      };
    })
    .catch(error => ({
      error,
      data: null,
    }));
};

export const validateDiscountCodeAPI = (discountCode: string) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .get(
      `/shopify/discounts/${user.website.subdomain}/${encodeURIComponent(
        discountCode || '',
      )}/validate/`,
      {
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
      },
    )
    .then(res => ({ data: res.data.result, error: null }))
    .catch(error => ({
      error,
      data: {},
    }));
};

export const saveShopifyMarketingAutomationAPI = async (campaign: any) => {
  const user = getUser();
  const axios = getAxios();

  return axios
    .post(
      `/shopify/extensions/marketing-activities/${user.website.subdomain}/ccp/`,
      campaign,
      {
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
      },
    )
    .then(res => ({ data: res.data, error: null }))
    .catch(error => ({
      error,
      data: {},
    }));
};

export const fetchShopifyMarketingAutomationAPI: (
  stepReference: string,
) => AxiosResponse = async (stepReference: string) => {
  const user = getUser();
  const axios = getAxios();

  return axios
    .get(
      `/shopify/extensions/marketing-activities/${user.website.subdomain}/ccp/${stepReference}/`,
      {
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
      },
    )
    .then(res => ({ data: res.data, error: null }))
    .catch(error => ({
      error,
      data: {},
    }));
};
