import { OnboardingPortalAuthType } from 'src/modules/OnboardingPortal/types';
import { get, post } from './axios';

const baseURL = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`;

export const initiateSignupAPI = async (payload: { email: string }) =>
  post('/signup/', payload, {
    baseURL,
  });

export const connectShopifyAPI = async (
  subdomain: string,
  dummySubdomain: string,
) =>
  post(
    `/auth/${dummySubdomain}/connect/shopify/`,
    { shopify_subdomain: subdomain },
    {
      baseURL,
    },
  );

export const verifyTokenAPI = async ({
  token,
  auth_method,
}: {
  token: string;
  auth_method: OnboardingPortalAuthType;
}) => {
  try {
    const response = await fetch(
      `${baseURL}/auth/verify/?token=${token}&auth_method=${auth_method}`,
    );

    const data = await response.json();

    if (data.errors) {
      return { user: null, error: data.errors[0] };
    }

    return { user: data.result, error: null };
  } catch (error) {
    return {
      user: null,
      error: {
        error_type: 'ServerError',
        message: 'Unable to verify token.',
      },
    };
  }
};

export const initiateGoogleOAuthAPI = async () =>
  get(`${baseURL}/auth/google/init/`);
