import { createModel } from '@rematch/core';
import {
  connectShopifyAPI,
  initiateGoogleOAuthAPI,
  initiateSignupAPI,
} from 'src/lib/api/onboarding-portal';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { RootModel } from 'src/store/models';

// Define error types for better type safety
type ErrorType =
  | 'ValidationError'
  | 'EmailSendingError'
  | 'AccountCreationError'
  | 'EmailRateLimitExceeded'
  | 'IPRateLimitExceeded'
  | 'UnknownError';

interface ApiError {
  error_type: ErrorType;
  message: string;
}

interface OnboardingState {
  showBackButton: boolean;
  merchantEmail: string;
  shopifyUrl: string;
  isFetching: boolean;
  isShopifyConnected: boolean;
}

const initialState = (): OnboardingState => ({
  showBackButton: false,
  merchantEmail: '',
  shopifyUrl: '',
  isFetching: false,
  isShopifyConnected: false,
});

const onboardingPortal = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    handleError(error: unknown, context: Record<string, unknown>) {
      const handleApiError = (apiError: ApiError) => {
        logErrorToSentry({
          error: new Error(apiError.error_type),
          extras: { error: apiError, ...context },
        });
        return dispatch.saveToast.showError(apiError.message);
      };

      if (error && typeof error === 'object' && 'error_type' in error) {
        return handleApiError(error as ApiError);
      }

      logErrorToSentry({
        error: new Error('UnknownError'),
        extras: { error, ...context },
      });
      return dispatch.saveToast.showError(
        typeof error === 'string' ? error : 'An unexpected error occurred',
      );
    },

    async initiateSignup(payload: { email: string }) {
      dispatch.onboardingPortal.setProp({ isFetching: true });
      try {
        const { data, error } = await initiateSignupAPI(payload);

        if (error) {
          return this.handleError(error, { email: payload.email });
        }
        return data;
      } catch (error) {
        return this.handleError(error, {
          email: payload.email,
          context: 'Error initiating owl portal signup',
        });
      } finally {
        dispatch.onboardingPortal.setProp({ isFetching: false });
      }
    },

    async connectShopify(payload: { subdomain: string }, rootState) {
      dispatch.onboardingPortal.setProp({ isFetching: true });
      try {
        const dummySubdomain = rootState?.user?.user?.website?.subdomain || '';
        const { data, error } = await connectShopifyAPI(
          payload.subdomain,
          dummySubdomain,
        );

        if (error) {
          return this.handleError(error, {
            subdomain: payload.subdomain,
            context: 'Error connecting to Shopify',
          });
        }

        return data.redirect_url;
      } catch (error) {
        return this.handleError(error, {
          subdomain: payload.subdomain,
          context: 'Error connecting to Shopify',
        });
      } finally {
        dispatch.onboardingPortal.setProp({ isFetching: false });
      }
    },

    async initiateGoogleOAuth() {
      const { data, error } = await initiateGoogleOAuthAPI();
      if (error) {
        return this.handleError(error, {
          context: 'Error initiating Google OAuth',
        });
      }
      return data;
    },
  }),

  reducers: {
    setProp(state, payload: Partial<OnboardingState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default onboardingPortal;
