import { createModel } from '@rematch/core';
import _set from 'lodash.set';
import getT from 'next-translate/getT';
import Router from 'next/router';
import {
  cancelPlanAPI,
  changeEmailPlanAPI,
  changePlanAPI,
  fetchPricingV3API,
  validateCouponAPI,
} from 'src/lib/api/pricing';
import { ChannelType } from 'src/lib/constants';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { handleRedirect, isFreePricingSlab } from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { UTMMedium } from 'src/store/models/common';
import {
  getDefaultPricingSlabs,
  getPricingSlabs,
  getTopLevelBillingPlanByConfig,
  handleEmailPricingSlabChange,
  switchToAppliedCouponPlan,
} from '../utils';
import {
  ChargeType,
  CouponCodeRule,
  Plan,
  PlanType,
  PricingCategory,
  PricingPlans,
  PricingSlab,
  RecommendedPlans,
  Topup,
} from './types';

const defaultPlans = {
  monthly: {
    free: [],
    business: [],
    enterprise: [],
  },
  annual: {
    free: [],
    business: [],
    enterprise: [],
  },
};

const defaultPricingSkus = {
  monthly: {
    business: {
      email: '',
      webpush: '',
      sms: '',
    },
    enterprise: {
      email: '',
      webpush: '',
      sms: '',
    },
    free: {
      email: '',
      webpush: '',
      sms: '',
    },
  },
  annual: {
    business: {
      email: '',
      webpush: '',
      sms: '',
    },
    enterprise: {
      email: '',
      webpush: '',
      sms: '',
    },
    free: {
      email: '',
      webpush: '',
      sms: '',
    },
  },
};

export type SelectedPricingSlabSku = typeof defaultPricingSkus;

export type PricingV3State = {
  plans: {
    isFetching: boolean;
    current: Plan | null;
    trialPlan?: Plan;
    topups: Topup[];
    recommendedPlans: RecommendedPlans;
    annualPlansConfig?: {
      business: { discount: number };
      enterprise: { discount: number };
    };
    is_eligible_for_trial: boolean;
  } & PricingPlans;
  pricing: {
    shouldShowContactUsCTA: boolean;
    selectedChargeType: ChargeType;
    isShowingTrials?: boolean;
    selectedCategory: PricingCategory;
    isChangingPlan: boolean;
    selectedPricingSlabSku: SelectedPricingSlabSku;
    selectedTrialPricingSlabSku: SelectedPricingSlabSku;
  };
  coupon: {
    code: string;
    isFetching: boolean;
    expiryDate: string | null;
    rule: CouponCodeRule;
    isValid?: boolean;
    message?: string;
  };
  onboardingPricing: {
    shouldShowContactUsCTA: boolean;
    selectedChargeType: ChargeType;
    selectedPricingSlabSku: string;
    isChangingPlan: boolean;
    selectedPlanType: PlanType;
  };
};

const defaultCoupon: PricingV3State['coupon'] = {
  isFetching: false,
  expiryDate: null,
  code: null,
  isValid: true,
  message: '',
  rule: {
    discountType: 'percentage',
    percentageOff: 0,
    amountOff: 0,
    currency: '',
    durationInMonths: 0,
    duration: '',
    appliesTo: {
      planTypes: [],
      plans: [],
      billingInterval: 'all',
      channels: [],
    },
  },
};

const initialStateV2: PricingV3State = {
  plans: {
    isFetching: false,
    webpush: defaultPlans,
    email: defaultPlans,
    omni: defaultPlans,
    current: null,
    topups: [],
    recommendedPlans: null,
    annualPlansConfig: null,
    is_eligible_for_trial: false,
  },
  pricing: {
    isShowingTrials: false,
    selectedCategory: 'omni',
    selectedChargeType: 'monthly',
    shouldShowContactUsCTA: false,
    isChangingPlan: false,
    selectedPricingSlabSku: defaultPricingSkus,
    selectedTrialPricingSlabSku: defaultPricingSkus,
  },
  coupon: defaultCoupon,
  onboardingPricing: {
    shouldShowContactUsCTA: false,
    selectedChargeType: 'monthly',
    selectedPricingSlabSku: '',
    isChangingPlan: false,
    selectedPlanType: 'free',
  },
};

const pricingV3 = createModel<RootModel>()({
  state: initialStateV2,
  effects: dispatch => ({
    async fetchPricing(payload: { selectFreeEmailPlanByDefault?: boolean }) {
      dispatch.pricingV3.setPlans({
        isFetching: true,
      });

      const { data, error } = await fetchPricingV3API();
      if (error) {
        dispatch.saveToast.showError('Error loading pricing');
        return;
      }

      dispatch.pricingV3.setPlans({
        isFetching: false,
        webpush: data.plans.webpush || [],
        email: data.plans.email || [],
        omni: data.plans.omni || [],
        current: data.current_plan,
        trialPlan: data.trial_plan,
        topups: data.topups || [],
        recommendedPlans: data.recommended_plans,
        annualPlansConfig: data.annual_plans_config,
        trialPlans: data?.trial_plans
          ? {
              omni: data.trial_plans?.omni,
            }
          : null,
        is_eligible_for_trial: data.is_eligible_for_trial,
      });

      if (payload?.selectFreeEmailPlanByDefault) {
        const chargeType = data.current_plan?.charge_type;
        const planType = data.current_plan?.plan_type;
        const monthyFreeOmniPlan =
          data.plans?.omni?.[chargeType || 'monthly']?.[
            planType || 'enterprise'
          ]?.[0]?.pricing_slabs?.[0];

        dispatch.pricingV3.setOnboarding({
          selectedPricingSlabSku: monthyFreeOmniPlan?.sku,
          selectedPlanType: planType,
        });
      }
    },

    async changeOnboardingPlan(payload: {
      sku: string;
      chargeType: ChargeType;
    }) {
      dispatch.pricingV3.setOnboarding({
        isChangingPlan: true,
      });

      const { data, error } = await changeEmailPlanAPI({
        email_pricing_slab_sku: payload.sku,
        charge_type: payload.chargeType,
      });

      if (error) {
        dispatch.saveToast.showError('Error changing plan');
      } else if (data.confirmation_url) {
        handleRedirect(data.confirmation_url);
      } else {
        // hard redirect to refetch the updated plan
        window.location.href = '/email';
      }

      dispatch.pricingV3.setOnboarding({
        isChangingPlan: false,
      });
    },

    async changeOnboardingPricingChargeType(payload: ChargeType, rootState) {
      const selectedChargeType = payload;

      const { omni } = rootState.pricingV3.plans;
      const { onboardingPricing } = rootState.pricingV3;

      const planType = onboardingPricing.selectedPlanType || 'enterprise';

      const monthyFreeOmniPlan =
        omni?.[selectedChargeType]?.[planType]?.[0]?.pricing_slabs?.[0];

      dispatch.pricingV3.setOnboarding({
        selectedChargeType,
        selectedPricingSlabSku: monthyFreeOmniPlan?.sku,
        selectedPlanType: planType,
      });
    },

    async toggleShowTrialPlans(showTrials: boolean) {
      dispatch.pricingV3.setPricing({
        isShowingTrials: showTrials,
      });
    },

    changeOnboardingPricingSlab(
      payload: {
        stepBy: 1 | -1;
      },
      rootState,
    ) {
      const { onboardingPricing, plans } = rootState.pricingV3;

      const pricingSlabs = getPricingSlabs({
        plans: plans.omni,
        chargeType: onboardingPricing.selectedChargeType,
        planType: onboardingPricing.selectedPlanType,
      });
      const emailSlabs = pricingSlabs.filter(
        slab => slab.channel === ChannelType.EMAIL,
      );

      const result = handleEmailPricingSlabChange({
        emailSlabs,
        pricing: onboardingPricing,
        stepBy: payload.stepBy,
        isPricingForOnboarding: true,
      });

      if (result) {
        dispatch.pricingV3.setOnboarding({
          selectedPricingSlabSku: result.newSku,
          shouldShowContactUsCTA: result.shouldShowContactUs,
        });
      }
    },

    hasEmailPlan(_, rootState): boolean {
      const { plans } = rootState.pricingV3;

      const isBrevoMerchant =
        rootState.user.user.website.flags.brevo_merchant === 'enabled';

      if (isBrevoMerchant) {
        return true;
      }

      if (!plans.current) return false;

      return Boolean(
        plans.current.pricing_slabs.find(
          slab => slab.channel === ChannelType.EMAIL,
        ),
      );
    },

    preselectPricingSlab(_, rootState) {
      const { plans, pricing } = rootState.pricingV3;

      const paidSlabs = getDefaultPricingSlabs(
        plans.omni,
        !plans.is_eligible_for_trial && plans.recommendedPlans,
      );
      const trialSlabs = getDefaultPricingSlabs(
        plans.trialPlans?.omni,
        plans.is_eligible_for_trial && plans.recommendedPlans,
      );

      dispatch.pricingV3.setPricing({
        selectedChargeType: plans.current.charge_type,
        selectedPricingSlabSku: paidSlabs,
        selectedTrialPricingSlabSku:
          trialSlabs || pricing.selectedTrialPricingSlabSku,
      });
    },

    async changePricingChargeType(payload: ChargeType) {
      dispatch.pricingV3.setPricing({
        selectedChargeType: payload,
      });
    },

    changePricingSlabV2(
      payload: {
        sku: string;
        channel: 'webpush' | 'email' | 'sms';
        planType: PlanType;
      },
      rootState,
    ) {
      const { pricing } = rootState.pricingV3;

      if (pricing.isShowingTrials) {
        const newSelectedTrialPricingSlabSku = _set(
          pricing.selectedTrialPricingSlabSku,
          `${pricing.selectedChargeType}.${payload.planType}.${payload.channel}`,
          payload.sku,
        );

        dispatch.pricingV3.setPricing({
          selectedTrialPricingSlabSku: newSelectedTrialPricingSlabSku,
        });
      } else {
        const newSelectedPricingSlabSku = _set(
          pricing.selectedPricingSlabSku,
          `${pricing.selectedChargeType}.${payload.planType}.${payload.channel}`,
          payload.sku,
        );

        dispatch.pricingV3.setPricing({
          selectedPricingSlabSku: newSelectedPricingSlabSku,
        });
      }
    },

    changePricingSlab(
      payload: {
        stepBy: 1 | -1;
        category: PricingCategory | 'email-omni' | 'webpush-omni';
        planType: PlanType;
      },
      rootState,
    ) {
      const { pricing, plans } = rootState.pricingV3;

      switch (payload.category) {
        case 'webpush-omni': {
          const webpushSlabs: PricingSlab[] = getPricingSlabs({
            plans: plans.omni,
            chargeType: pricing.selectedChargeType,
            planType: payload.planType,
          }).filter(slab => slab.channel === ChannelType.WEBPUSH);
          const currentSlabIndex = webpushSlabs.findIndex(
            slab =>
              slab.sku ===
              pricing.selectedPricingSlabSku[pricing.selectedChargeType][
                payload.planType
              ].webpush,
          );

          const nextSlab = webpushSlabs[currentSlabIndex + payload.stepBy];

          if (nextSlab) {
            const newSelectedPricingSlabSku = _set(
              pricing.selectedPricingSlabSku,
              `${pricing.selectedChargeType}.${payload.planType}.webpush`,
              nextSlab.sku,
            );
            dispatch.pricingV3.setPricing({
              selectedPricingSlabSku: newSelectedPricingSlabSku,
            });
          }
          break;
        }

        case 'email-omni': {
          const emailSlabs: PricingSlab[] = getPricingSlabs({
            plans: plans.omni,
            chargeType: pricing.selectedChargeType,
            planType: payload.planType,
          }).filter(slab => slab.channel === ChannelType.EMAIL);

          const result = handleEmailPricingSlabChange({
            emailSlabs,
            pricing,
            stepBy: payload.stepBy,
            planType: payload.planType,
            chargeType: pricing.selectedChargeType,
          });

          if (result) {
            const newSelectedPricingSlabSku = _set(
              pricing.selectedPricingSlabSku,
              `${pricing.selectedChargeType}.${payload.planType}.email`,
              result.newSku,
            );
            dispatch.pricingV3.setPricing({
              selectedPricingSlabSku: newSelectedPricingSlabSku,
              shouldShowContactUsCTA: result.shouldShowContactUs,
            });
          }
          break;
        }

        default:
          break;
      }
    },

    async changePricingPlan(
      payload: {
        selectedSku: string | string[];
        topLevelBillingSku?: string;
        category: PricingCategory;
        plan_type?: PlanType;
        isTotalPriceZero?: boolean;
        utmMedium?: UTMMedium;
        utmSource?: string;
      },
      rootState,
    ) {
      dispatch.pricingV3.setPricing({
        isChangingPlan: true,
      });

      const {
        plans,
        pricing: { selectedChargeType, isShowingTrials },
        coupon,
      } = rootState.pricingV3;
      const { utmMedium, utmSource } = rootState.common;

      const topLevelBillingPlanSku = getTopLevelBillingPlanByConfig({
        plans,
        chargeType: selectedChargeType,
        category: payload.category,
        planType: payload.plan_type,
        isTrial: isShowingTrials,
      });

      if (topLevelBillingPlanSku || payload.topLevelBillingSku) {
        if (payload.isTotalPriceZero) {
          const { error } = await cancelPlanAPI({
            billing_plan_sku:
              payload.topLevelBillingSku || topLevelBillingPlanSku?.sku,
            pricing_slab_skus: [].concat(payload.selectedSku),
          });

          if (error) {
            dispatch.saveToast.showError('Error changing plan');
          } else {
            // hard redirect to refetch the updated plan
            window.location.href = '/pricing';
          }
        } else {
          const { data, error } = await changePlanAPI({
            billing_plan_sku:
              payload.topLevelBillingSku || topLevelBillingPlanSku?.sku,
            pricing_slab_skus: [].concat(payload.selectedSku),
            coupon_code: coupon.code || null,
            utm_source: payload.utmSource || utmSource || 'pushowl',
            utm_medium: payload.utmMedium || utmMedium,
          });

          if (error) {
            dispatch.saveToast.showError('Error changing plan');
          } else if (data.confirmation_url) {
            handleRedirect(data.confirmation_url);
          }
        }
      } else {
        logErrorToSentry({
          error: 'No top level billing plan sku found',
          extras: {
            payload,
          },
        });
        dispatch.saveToast.showError('Error changing plan');
      }

      dispatch.pricingV3.setPricing({
        isChangingPlan: false,
      });
    },

    async validateCoupon({ code }: { code: string }) {
      const t = await getT(Router.locale, 'pricing');
      dispatch.pricingV3.setCoupon({
        isFetching: true,
      });

      const { error } = await validateCouponAPI({ code });

      if (error) {
        dispatch.pricingV3.setCoupon({
          isFetching: false,
          message: t('invalid_coupon_code_err_msg'),
          isValid: false,
        });
      } else {
        dispatch.pricingV3.setCoupon({
          message: '',
          isValid: true,
          isFetching: false,
        });
      }
    },

    async applyCoupon(payload: { code: string }, rootState) {
      const pricingT = await getT(Router.locale, 'pricing');
      const toastT = await getT(Router.locale, 'toasts');

      dispatch.pricingV3.setCoupon({
        isFetching: true,
      });

      const { data, error } = await validateCouponAPI({
        code: payload.code,
      });

      if (error) {
        dispatch.pricingV3.setCoupon({
          ...defaultCoupon,
          message: pricingT('invalid_coupon_code_err_msg'),
          isValid: false,
        });
        return;
      }
      // TODO: remove this block after coupon is supported with stripe payment mode
      {
        const { plans: pricingPlans, pricing } = rootState.pricingV3;
        const planTypes: PlanType[] = ['free', 'business', 'enterprise'];
        const chargeTypes = Object.keys(pricing.selectedPricingSlabSku) as Array<keyof SelectedPricingSlabSku>;

        const invalidPlan = chargeTypes.some(chargeType =>
          planTypes.some(planType => {
        const smsSku = pricing.selectedPricingSlabSku[chargeType][planType].sms;
        const smsSlabs = getPricingSlabs({
          plans: pricingPlans.omni,
          chargeType,
          planType,
        }).filter(slab => slab.channel === ChannelType.SMS);
        const smsSlab = smsSlabs.find(slab => slab.sku === smsSku);
        return smsSlab && !isFreePricingSlab(smsSlab);

          }),
        );
        if (invalidPlan) {
          dispatch.saveToast.showError(toastT('coupon_only_for_free_sms'));

        } else {
          dispatch.saveToast.showDone(toastT('Coupon applied'));

        }
      }

      const {
        code,
        expiry_date: expiryDate,
        rule: {
          discount_type: discountType,
          percentage_off,
          amount_off,
          currency,
          duration,
          duration_in_months,
          applies_to: {
            plan_types: planTypes,
            plans,
            billing_interval: billingInterval,
            channels,
          },
        },
      } = data;

      const coupon: PricingV3State['coupon'] = {
        message: 'Discount applied',
        isValid: true,
        isFetching: false,
        code,
        expiryDate,
        rule: {
          discountType,
          percentageOff: parseFloat(percentage_off),
          amountOff: parseFloat(amount_off),
          currency,
          duration,
          durationInMonths: parseInt(duration_in_months, 10),
          appliesTo: {
            planTypes,
            plans,
            billingInterval,
            channels,
          },
        },
      };

      dispatch.pricingV3.setCoupon(coupon);

      const { plans: pricingPlans, pricing } = rootState.pricingV3;

      const { chargeType } = switchToAppliedCouponPlan({
        appliedTo: coupon.rule.appliesTo,
        plans: pricingPlans,
      });

      dispatch.pricingV3.setPricing({
        selectedChargeType: chargeType || pricing.selectedChargeType,
      });
    },

    resetCoupon() {
      dispatch.pricingV3.setCoupon({ ...defaultCoupon });
    },

    goToPricing(payload: { utmMedium?: UTMMedium; utmSource?: string }) {
      dispatch.common.setUTM({
        utmMedium: payload?.utmMedium,
        utmSource: payload?.utmSource,
      });

      Router.push('/pricing');
    },

    async changeToTrialPlan(
      payload: { utmMedium?: UTMMedium; utmSource?: string },
      rootState,
    ) {
      const { trialPlan } = rootState.pricingV3.plans;

      if (trialPlan) {
        await dispatch.pricingV3.changePricingPlan({
          category: 'webpush',
          selectedSku: trialPlan.pricing_slabs?.[0]?.sku,
          topLevelBillingSku: trialPlan.sku,
          utmSource: payload.utmSource,
          utmMedium: payload.utmMedium,
        });
      } else {
        dispatch.pricingV3.goToPricing({
          utmMedium: payload.utmMedium,
          utmSource: payload.utmSource,
        });
      }
    },

    getOnboardingTrialPlan(_, rootState): Plan | undefined {
      const { plans } = rootState.pricingV3;
      const {
        website: { shopify_plan },
      } = rootState.user.user;

      if (shopify_plan === 'shopify_plus') {
        return plans?.recommendedPlans?.enterprise;
      }

      return plans?.recommendedPlans?.business;
    },
  }),

  reducers: {
    setPlans(state, payload: Partial<PricingV3State['plans']>) {
      return {
        ...state,
        plans: {
          ...state.plans,
          ...payload,
        },
      };
    },
    setPricing(state, payload: Partial<PricingV3State['pricing']>) {
      return {
        ...state,
        pricing: {
          ...state.pricing,
          ...payload,
        },
      };
    },
    setCoupon(
      state: PricingV3State,
      payload: Partial<PricingV3State['coupon']>,
    ) {
      return {
        ...state,
        coupon: {
          ...state.coupon,
          ...payload,
        },
      };
    },
    setOnboarding(
      state,
      payload: Partial<PricingV3State['onboardingPricing']>,
    ) {
      return {
        ...state,
        onboardingPricing: {
          ...state.onboardingPricing,
          ...payload,
        },
      };
    },
  },
});

export default pricingV3;
