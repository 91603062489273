// @ts-nocheck

export const loadAnalytics = intercomData => {
  // Segment
  const segmentScript = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_API}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_API}", { integrations: { Intercom: false, FullStory: false } });
  analytics.page();
  }}();`;

  const intercomScript = `
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        var d = document;
        var i = function() {
            i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        };
        l();
      }`;

  const segmentScriptTag = document.createElement('script');
  segmentScriptTag.type = 'text/javascript';
  segmentScriptTag.text = segmentScript;
  document.body.appendChild(segmentScriptTag);

  const intercomScriptTag = document.createElement('script');
  intercomScriptTag.type = 'text/javascript';
  intercomScriptTag.text = intercomScript;
  document.body.appendChild(intercomScriptTag);
  if (window.Intercom) {
    window.Intercom('boot', intercomData);

    const urlParams = new URLSearchParams(window.location.search);
    const openIntercom = urlParams.get('open_intercom');
    const message = urlParams.get('message');

    if (openIntercom === 'true' && message) {
      window.Intercom('showNewMessage', decodeURIComponent(message));
    }
  }

  const metaPixelScript = `!(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  );
  fbq('init', '569439912209998');
  `;

  const metaPixelScriptTag = document.createElement('script');
  metaPixelScriptTag.type = 'text/javascript';
  metaPixelScriptTag.text = metaPixelScript;
  document.body.appendChild(metaPixelScriptTag);

  const gtagManagerSrc =
    'https://www.googletagmanager.com/gtag/js?id=G-MESRBYCSCD';

  const gtagManagerScriptTag = document.createElement('script');
  gtagManagerScriptTag.type = 'text/javascript';
  gtagManagerScriptTag.src = gtagManagerSrc;
  document.body.appendChild(gtagManagerScriptTag);

  const gtagScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-MESRBYCSCD');
  `;

  const gtagScriptTag = document.createElement('script');
  gtagScriptTag.type = 'text/javascript';
  gtagScriptTag.text = gtagScript;
  document.body.appendChild(gtagScriptTag);

  const linkedInScript = `_linkedin_partner_id = "6952188";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  
  (function(l) {
    if (!l) {
      window.lintrk = function(a,b) {
        window.lintrk.q.push([a,b])
      };
      window.lintrk.q = [];
    }
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);`;

  const linkedInScriptTag = document.createElement('script');
  linkedInScriptTag.type = 'text/javascript';
  linkedInScriptTag.text = linkedInScript;
  document.body.appendChild(linkedInScriptTag);

  // For the noscript part with the tracking pixel
  const noScriptElement = document.createElement('noscript');
  const imgElement = document.createElement('img');
  imgElement.height = '1';
  imgElement.width = '1';
  imgElement.style.display = 'none';
  imgElement.alt = '';
  imgElement.src = 'https://px.ads.linkedin.com/collect/?pid=6952188&fmt=gif';
  noScriptElement.appendChild(imgElement);
  document.body.appendChild(noScriptElement);

  const clarityScript = `
  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "o2n7mc0syj");
  `;

  const clarityScriptTag = document.createElement('script');
  clarityScriptTag.type = 'text/javascript';
  clarityScriptTag.text = clarityScript;
  document.body.appendChild(clarityScriptTag);
};

export const RB2B_SCRIPT_SRC =
  '!function () {var reb2b = window.reb2b = window.reb2b || []; if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"]; reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments); args.unshift(method);reb2b.push(args);return reb2b;};}; for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);} reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true; script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz"; var first = document.getElementsByTagName("script")[0]; first.parentNode.insertBefore(script, first);}; reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("Y46DJ4HR3M61");}();';

export const getUTMParamFrameStorage = () => {
  const key = 'pushowl_frame_storage=';
  if (typeof document === 'undefined' || !document.cookie) {
    return null;
  }
  const decodedCookies = decodeURIComponent(document.cookie).split(';');
  if (!decodedCookies) {
    return null;
  }
  const cookie = decodedCookies
    .map(cookie => cookie.trim().split('?'))
    .find(([cookieKey]) => cookieKey === key);

  if (!cookie || cookie.length < 2) {
    return null;
  }
  const utmParams = decodeURIComponent(cookie[1]);
  return Object.fromEntries(new URLSearchParams(utmParams));
};

export const getServerSideTrackEventProperties = () => {
  const utmObj = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  const respObj: { [key: string]: any } = {};
  respObj['clickid'] = utmObj?.fbclid || utmObj?.gclid;
  respObj['utm_source'] = utmObj?.utm_source;
  respObj['utm_campaign'] = utmObj?.utm_campaign;
  respObj['utm_medium'] = utmObj?.utm_medium;
  return respObj;
};
