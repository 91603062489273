import { createModel } from '@rematch/core';
import {
  fetchOrderCountAPI,
  toggleOnboardingStatusForMerchantAPI,
} from 'src/lib/api/home';
import {
  getEmailOnboardingCardStatus,
  getEmailOnboardingStatus,
  getIntegratedDashboardCardSummary,
  getSMSOnboardingCardStatus,
  getWebpushOnboardingStatus,
  toggleEmailOnboardingCardStatus,
  toggleSMSOnboardingCardStatus,
} from 'src/lib/api/integrated-dashboard';
import { CampaignType } from 'src/modules/campaigns/models';
import { RootModel } from 'src/store/models';
import {
  EmailOnboardingStatus,
  IntegratedDashboardState,
  OnboardingPreference,
  SMSOnboardingStatus,
  WebpushOnboardingStatus,
} from './types';

const initialState: IntegratedDashboardState = {
  isLoading: false,
  onboardingPreference: 'neither',
  isWebpushOnboardingCompleted: false,
  isEmailOnboardingCompleted: false,
  isSMSOnboardingCompleted: false,
  isEmailActivated: false,
  orderCount: 0,
  fetchingOrderCount: true,
  onboardingStatus: {
    webpush: 'not_started',
    email: 'not_started',
    sms: 'not_started',
  },
  stats: {
    total_subscribers: {
      webpush: 0,
      email: 0,
      sms: 0,
    },
    click_rate: {
      webpush: 0,
      email: 0,
      sms: 0,
    },
    conversion_rate: {
      webpush: 0,
      email: 0,
      sms: 0,
    },
    attributed_revenue: { webpush: 0, email: 0, sms: 0 },
    total_campaigns_sent: {
      sms: 0,
    },
  },
};

const integratedDashboard = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async fetchOrderCount() {
      const { data, error } = await fetchOrderCountAPI();
      if (error) {
        return;
      }
      dispatch.integratedDashboard.setOnboardingState({
        orderCount: data.order_count,
      });

      dispatch.integratedDashboard.setOnboardingState({
        fetchingOrderCount: false,
      });
    },
    async syncPreferences(_, rootState) {
      const {
        integratedOnboarding: {
          onboarding: { onboarding_setup_choice },
        },
      } = rootState;

      dispatch.integratedDashboard.setOnboardingState({
        isLoading: true,
      });

      await dispatch.integratedOnboarding.fetchMerchantPreferences().then(() =>
        dispatch.integratedDashboard.setOnboardingState({
          onboardingPreference: onboarding_setup_choice,
          isLoading: false,
        }),
      );
    },

    async checkIfEmailActivated() {
      try {
        const { data, error } = await getEmailOnboardingStatus();
        if (error) {
          dispatch.integratedDashboard.setOnboardingState({
            isEmailActivated: false,
          });
        }
        dispatch.integratedDashboard.setOnboardingState({
          isEmailActivated: data.is_email_activated,
        });
      } catch (error) {
        dispatch.integratedDashboard.setOnboardingState({
          isEmailActivated: false,
        });
      }
    },

    async checkOnboardingStatus(): Promise<WebpushOnboardingStatus | null> {
      try {
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: true,
        });

        const { data, error } = await getWebpushOnboardingStatus();
        const { data: emailData, error: emailError } =
          await getEmailOnboardingStatus();
        if (error || emailError) {
          dispatch.saveToast.showError('onboarding_status_error');
          dispatch.integratedDashboard.setOnboardingState({
            isLoading: false,
          });
          return null;
        }

        const isCompleted = data.webpush_onboarding_status === 'completed';

        // TODO: refactor this to use the new api that has been made where all the channels onboarding status is returned
        dispatch.sms.setState({
          shouldAllowMerchantToCreateCampaign:
            emailData.is_sms_onboarding_finished,
        });
        dispatch.integratedDashboard.setOnboardingState({
          isWebpushOnboardingCompleted: isCompleted,
          isEmailOnboardingCompleted: emailData.email_onboarding_status,
          isSMSOnboardingCompleted: emailData.is_sms_onboarding_finished,
          isLoading: false,
        });
        return data.webpush_onboarding_status;
      } catch (error) {
        dispatch.saveToast.showError('onboarding_status_error');
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: false,
        });
        return null;
      }
    },

    async getSummary(_, rootState) {
      try {
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: true,
        });

        const states = [
          CampaignType.SENT,
          CampaignType.SCHEDULED,
          CampaignType.PAUSED,
        ];
        const {
          settings: { attributionWindow },
        } = rootState;
        const {
          reports: { campaignTypes, segmentIds, timeFilter },
        } = rootState.campaigns;

        const { data, error } = await getIntegratedDashboardCardSummary(
          states,
          { timeFilter, segmentIds, campaignTypes },
          attributionWindow,
        );

        if (error) {
          dispatch.saveToast.showError('summary_error');
          dispatch.integratedDashboard.setOnboardingState({
            isLoading: false,
          });
          return;
        }
        dispatch.integratedDashboard.setOnboardingState({
          stats: data,
          isLoading: false,
        });
      } catch (error) {
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: false,
        });
        dispatch.saveToast.showError('summary_error');
      }
    },

    async getSkippedOnboardingStatus() {
      dispatch.integratedDashboard.setOnboardingState({
        isLoading: true,
      });
      try {
        const { data: emailData, error: emailError } =
          await getEmailOnboardingCardStatus();
        const { data: webpushData, error: webpushError } =
          await getWebpushOnboardingStatus();
        const { data: smsData, error: smsError } =
          await getSMSOnboardingCardStatus();

        if (emailError || webpushError || smsError) {
          dispatch.integratedDashboard.setOnboardingState({
            isLoading: false,
          });
          return;
        }

        dispatch.integratedDashboard.setOnboardingState({
          onboardingStatus: {
            webpush: webpushData.webpush_onboarding_status,
            email: emailData.email_onboarding_banner_status,
            sms: smsData.sms_onboarding_banner_status,
          },
          isLoading: false,
        });
      } catch {
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: false,
        });
      }
    },

    async callSkipOnboardingAPIs(toSkip: OnboardingPreference) {
      try {
        if (toSkip === 'webpush') {
          const { error } = await toggleOnboardingStatusForMerchantAPI(
            'skipped',
          );
          if (error) {
            dispatch.saveToast.showError(
              'integrated_dashboard_skipping_webpush_error',
            );
          }
        } else if (toSkip === 'email') {
          await dispatch.integratedDashboard.setEmailOnboardingCardStatus({
            status: 'skipped',
            hideBanner: false,
          });
        } else if (toSkip === 'sms') {
          await dispatch.integratedDashboard.setSMSOnboardingCardStatus({
            status: 'skipped',
            hideBanner: false,
          });
        }
      } catch {
        dispatch.integratedDashboard.setOnboardingState({
          isLoading: false,
        });
      }
    },

    async setSkippedOnboardingStatus(
      props: {
        toSkip: OnboardingPreference;
        setLocally: boolean;
      },
      rootState,
    ) {
      dispatch.integratedDashboard.setOnboardingState({
        isLoading: true,
      });

      if (!props.setLocally) {
        await dispatch.integratedDashboard.callSkipOnboardingAPIs(props.toSkip);
      }

      const { onboardingStatus } = rootState.integratedDashboard;

      dispatch.integratedDashboard.setOnboardingState({
        onboardingPreference: props.toSkip === 'email' ? 'webpush' : 'email',
        onboardingStatus: { ...onboardingStatus, [props.toSkip]: 'skipped' },
        isLoading: false,
      });
    },

    async setEmailOnboardingCardStatus(
      props: { status: EmailOnboardingStatus; hideBanner: boolean },
      rootState,
    ) {
      const { status } = props;

      const { error } = await toggleEmailOnboardingCardStatus(status);
      if (error && !props.hideBanner) {
        dispatch.saveToast.showError(
          'integrated_dashboard_skipping_email_error',
        );
        return;
      }
      dispatch.integratedDashboard.setOnboardingState({
        onboardingStatus: {
          ...rootState.integratedDashboard.onboardingStatus,
          email: status,
        },
      });
    },

    async setSMSOnboardingCardStatus(
      props: { status: SMSOnboardingStatus; hideBanner: boolean },
      rootState,
    ) {
      const { status } = props;

      const { error } = await toggleSMSOnboardingCardStatus(status);
      if (error && !props.hideBanner) {
        dispatch.saveToast.showError('integrated_dashboard_skipping_sms_error');
        return;
      }
      dispatch.integratedDashboard.setOnboardingState({
        onboardingStatus: {
          ...rootState.integratedDashboard.onboardingStatus,
          sms: status,
        },
      });
    },
  }),

  reducers: {
    setOnboardingState(state, payload: Partial<IntegratedDashboardState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default integratedDashboard;
