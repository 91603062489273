import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { useOverrideBrevoDocumentTitle } from 'src/hooks/use-override-brevo-document-title';
import { getStorage } from 'src/lib/storage';
import { URLsWithoutAuth } from 'src/store/models/user';
import { LoadingScreen } from './LoadingScreen';
import { MaintenancePage } from './MaintenancePage';

const IS_UNDER_MAINTENANCE =
  process.env.NEXT_PUBLIC_IS_UNDER_MAINTENANCE === 'true';

export const AppWrapper = ({ children }) => {
  useOverrideBrevoDocumentTitle();
  const posthog = usePostHog();

  useEffect(() => {
    // Using plain js search param since Next js query param is available after a delay
    const queryParams = new URLSearchParams(window.location.search);
    const stepReference = queryParams.get('step_reference');

    // Store step_reference in storage if it is present for shopify marketing automation
    // TODO: Move this to Redux
    const storage = getStorage();
    storage.set('step_reference', stepReference);
  }, []);

  const {
    state: { isAuthenticating, user },
    actions: { refetchUser, shopifyLogin, setIsAuthenticating },
  } = useGlobalState('user');

  const { pathname } = useRouter();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    //  Checking host param for triggering installation flow
    const hostParam = queryParams.get('host');
    if (!window.location.pathname.includes('onboarding-portal')) {
      if (hostParam) {
        shopifyLogin();
      } else {
        refetchUser();
      }
    } else {
      setIsAuthenticating(false);
    }
  }, []);

  useEffect(() => {
    if (user?.website?.subdomain && !isAuthenticating) {
      posthog.identify(user.website.subdomain, {
        email: user?.email,
        name: user?.name,
        created_at: user?.created_at,
        brevo_email: user?.website?.flags?.brevo_email,
        content_calendar: user?.website?.flags?.content_calendar,
        platform: user?.website?.platform,
        plan_price: user?.current_plan?.plan_price,
        plan_charge_type: user?.website?.current_plan?.charge_type,
        plan_type: user?.website?.current_plan?.plan_type,
        is_under_trial: user?.website?.current_plan?.is_under_trial,
      });
    }
  }, [user, isAuthenticating]);

  if (IS_UNDER_MAINTENANCE) {
    return <MaintenancePage />;
  }

  if (isAuthenticating && !URLsWithoutAuth.includes(pathname)) {
    return <LoadingScreen />;
  }

  return children;
};
